import { React, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-bootstrap";
import logo from '../img/logo.jpg';
import environment_horticulture from '../img/_1.jpg';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import '../css/dash.css';
import * as Icon from "react-bootstrap-icons";
import '../css/contactUs.css';
import $ from 'jquery';
import { FaMapMarkedAlt } from "react-icons";
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';

function Sample() {

    return (
        <>
            <Navbar collapseOnSelect expand="lg" bg="white" variant="white" className="flex-column">
                <Container>
                    <Navbar.Brand href="/"><img src={logo} className="logo" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">

                        <Nav className="ms-auto">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="/aboutUs">About Us</Nav.Link>
                            <Nav.Link href="/contactUs">Contact Us</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div className="main row container">
                <div className="col-md-6">
                    <section class="about-section">
                        <div class="container">
                            <div class="row">
                                <div class="content-column col-12 order-2">
                                    <div class="inner-column">
                                        <div class="text">
                                            <b>SVAR Environments</b> is an expert consultancy services provider for various environmental services requirements namely, horticulture and landscaping, gardening, water and waste water management, green belt, ‘miyawaki’ plantation, plant nursery, green waste management, soil conservation and improvisation, plot area flood risk management, irrigation management, percolation well, rainwater harvesting, environmental requirements for surrounding areas, carbon footprint, factory waste management, special training program for environmental awareness, tree plantation in adopted areas, ground water studies, water pollution, land use pattern, site visits, water audit, environmental audit etc.
                                        </div>
                                        {/* <div class="btn-box">
                                    <a href="#" class="theme-btn btn-style-one">Contact Us</a>
                                </div> */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
                <div className="col-md-6">
                    <Slider>
                        <img src="https://s18.postimg.cc/9vhgup22x/img1.jpg" />
                        <img src="https://s18.postimg.cc/vunvhvvrt/img2.jpg" />
                        <img src="https://s18.postimg.cc/tdc4amjl5/img3.jpg" />
                    </Slider>
                </div>
            </div>

        </>
    )

}

export default Sample
