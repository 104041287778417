import { React, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-bootstrap";
import logo from '../img/logo.jpg';
import environment_horticulture from '../img/_1.jpg';
import softlandscape from '../img/softlandscape.jpeg';
import hardlandscape from '../img/hardlandscape.jpeg';
import herbal from '../img/herbal.jpeg';
import arboriculture from '../img/arboriculture.jpeg';
import xeriscaping from '../img/xeriscaping.jpeg';
import solarenergy from '../img/solarenergy.jpeg';
import greenbuilding from '../img/greenbuilding.jpeg';
import greenbelt1 from '../img/greenbelt1.jpeg';
import biodiversity from '../img/bioDiversity1.jpg';
import oxygen1 from '../img/oxygen1.jpg';
import m2 from '../img/m2.jpg';
import plantnursery1 from '../img/plantNursery1.jpg';
import organicfarming from '../img/organic2.jpg';
import organicproducts from '../img/organicproducts1.jpg';
import gaushala from '../img/gaushala1.jpg';
import greenwaste from '../img/greenwaste2.jpg';
import wastewater from '../img/waterWaste2.jpg';
import inhouseair from '../img/inhouseair2.png';
import plotsurveying from '../img/plotSurvey2.jpg';
import soilconservation from '../img/soilConservation2.jpg';
import floodrisk from '../img/floodRisk1.jpg';
import irrigation from '../img/irrigation2.jpg';
import rainwater from '../img/rainwater1.jpg';
import solarharvesting from '../img/solarHarvesting1.png';
import environmentalside from '../img/EnvironmentalSideArea1.jpeg';
import carbon from '../img/carbon1.jpg';
import factorywaste from '../img/factoryWaste1.jpg';
import awareness from '../img/awareness1.jpg';
import adopted from '../img/adoptedareas2.jpeg';
import dustmanagement from '../img/dustmanagement.jpg';
import gramgaushala from '../img/gramgaushala1.jpg';
import gramsolar from '../img/gramsolar1.jpg';
import dronesurvey from '../img/dronesurvey1.jpg';
import roadsidefood from '../img/roadsidehumanfood1.jpg';
import climatechange from '../img/climatemanagement1.jpg';
import gissurvey from '../img/gissurvey1.jpg';
import groundwater from '../img/groundwater2.jpg';
import soilerosion from '../img/soilerosion1.jpg';
import waterpollution from '../img/waterpollution2.jpeg';
import landuse from '../img/landuse1.jpg';
import sitevisit from '../img/sitevisits.jpg';
import flowmeter from '../img/flowmeter1.jpg';
import laboratory from '../img/laboratory2.jpg';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import '../css/dash.css';
import $ from 'jquery';
import { useEffect } from "react";

function Dashboard() {

    const [isActive, setActive] = useState(false);

    useEffect(() => {
        setActive("true")
    }, []);


    const handleChange = (e) => {
        setActive(!isActive);
    }

    return (
        <>
            <Navbar collapseOnSelect expand="lg" bg="white" variant="white" className="flex-column">
                <Container>
                    <Navbar.Brand href="/"><img src={logo} className="logo" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">

                        <Nav className="ms-auto">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="/aboutUs">About Us</Nav.Link>
                            <Nav.Link href="/contactUs">Contact Us</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <nav class="cd-side-nav">
                <ul>
                    <li><a href="#0">Horticulture & Landscaping</a></li>
                    <li><a href="#0">Soft Landscaping</a></li>
                    <li><a href="#0">Hard Landscaping</a></li>
                    <li><a href="#0">All Notifications</a></li>
                    <li><a href="#0">Herbal and medicinal Garden</a></li>
                    <li><a href="#0">Arboriculture</a></li>
                    <li><a href="#0">Xeriscaping Agriculture</a></li>
                    <li><a href="#0">Solar energy</a></li>
                    <li><a href="#0">Green building</a></li>
                    <li><a href="#0">Green belt</a></li>
                    <li><a href="#0">Bio Diversity Park</a></li>
                    <li><a href="#0">Oxygen Park</a></li> 
                    <li><a href="#0">Miyawaki plantation</a></li>
                    <li><a href="#0">Plant nursery</a></li>
                    <li><a href="#0">Organic farming</a></li>
                    <li><a href="#0">Organic products</a></li>
                    <li><a href="#0">Gaushala management</a></li>
                    <li><a href="#0">Green waste management</a></li>
                    <li><a href="#0">Water and waste water managements</a></li>
                    <li><a href="#0">In house air quality management</a></li>
                    <li><a href="#0">Plot surveying from environmental aspects</a></li>
                    <li><a href="#0">Soil conservation and improvisation</a></li>
                    <li><a href="#0">Plot area flood risk management</a></li>
                    <li><a href="#0">Irrigation management Percolation well</a></li>
                    <li><a href="#0">Rainwater harvesting</a></li>
                    <li><a href="#0">Solar harvesting</a></li>
                    <li><a href="#0">Environmental requirements for surrounding areas</a></li>
                    <li><a href="#0">Carbon footprint</a></li>
                    <li><a href="#0">Factory waste management</a></li>
                    <li><a href="#0">Special training program for environmental awareness</a></li>
                    <li><a href="#0">Tree plantation in adopted areas</a></li>
                    <li><a href="#0">Adopted area maintenance work</a></li>
                    <li><a href="#0">Dust management</a></li>
                    <li><a href="#0">Gram gaushala</a></li>
                    <li><a href="#0">Gram solar</a></li>
                    <li><a href="#0">Gram green cover survey</a></li>
                    <li><a href="#0">Study by drone surveying</a></li>
                    <li><a href="#0">Roadside human food plantation</a></li>
                    <li><a href="#0">Climate change management</a></li> 
                    <li><a href="#0">GIS surveying services</a></li>
                    <li><a href="#0">Environmental clearance certification</a></li>
                    <li><a href="#0">Environmental impact assessment</a></li>
                    <li><a href="#0">Ground water studies</a></li>
                    <li><a href="#0">Soil erosion</a></li>
                    <li><a href="#0">Water pollution</a></li>
                    <li><a href="#0">Land use pattern</a></li>
                    <li><a href="#0">Ecology bio-diversity risk assessment Site visits</a></li>
                    <li><a href="#0">CGWA compliance</a></li>
                    <li><a href="#0">Water audit</a></li>
                    <li><a href="#0">Piezometer installation</a></li>
                    <li><a href="#0">Environmental audit</a></li>
                    <li><a href="#0">Coordination for all types of environmental laboratory tests</a></li>
                    <li><a href="#0">analysis and reports etc.</a></li>
                </ul>
            </nav>
            <div class="main">
                
                <h1 className="d-none mobileViewCaption" style={{background:'#727271', color:'white', padding:'0.5rem'}}>Click Here to View all Services List</h1>
                    
                <h1>Our Services in fields of Environmental, Horticulture and Landscaping</h1>
                <ul class="cards">
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={environment_horticulture} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Horticulture & Landscaping</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={hardlandscape} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Hard Landscaping</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={softlandscape} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Soft Landscaping</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={herbal} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Herbal and Medicinal Plantation</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={arboriculture} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Arboriculture</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={xeriscaping} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Xeriscaping Agriculture</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={solarenergy} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Solar energy</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={greenbuilding} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Green building</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={greenbelt1} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Green belt</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={biodiversity} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Bio-Diversity Park</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={oxygen1} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Oxygen Park</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={m2} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Miyawaki Plantation</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={plantnursery1} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Plant nursery</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={organicfarming} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Organic farming</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={organicproducts} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Organic Products</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={gaushala} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Gaushala Management</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={greenwaste} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Green waste management</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={wastewater} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Water and wastewater management</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={inhouseair} /></div>
                            <div class="card_content">
                                <h2 class="card_title">In house air quality management</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={plotsurveying} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Plot Survey from Environmental Aspects</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={soilconservation} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Soil conservation and improvisation</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={floodrisk} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Plot area flood risk management</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={irrigation} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Irrigation management & Percolation well</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={rainwater} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Rainwater harvesting</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={solarharvesting} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Solar harvesting</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={environmentalside} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Environmental requirements for surrounding areas</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={carbon} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Carbon Footprint</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={factorywaste} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Factory waste management</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={awareness} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Environmental Awareness</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={adopted} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Tree plantation in adopted areas</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src="https://picsum.photos/500/300/?image=10" /></div>
                            <div class="card_content">
                                <h2 class="card_title">Adopted area maintenance work</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={dustmanagement} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Dust Management</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={gramgaushala} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Gram Gaushala</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={gramsolar} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Gram solar</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={dronesurvey} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Gram green cover survey / study by drone surveying</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={roadsidefood} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Roadside human food plantation</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={climatechange} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Climate change management</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={gissurvey} /></div>
                            <div class="card_content">
                                <h2 class="card_title">GIS surveying services</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src="https://picsum.photos/500/300/?image=10" /></div>
                            <div class="card_content">
                                <h2 class="card_title">Environmental clearance certification</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src="https://picsum.photos/500/300/?image=10" /></div>
                            <div class="card_content">
                                <h2 class="card_title">Environmental impact assessment</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={groundwater} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Ground water studies</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={soilerosion} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Soil Eroison</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={waterpollution} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Water pollution</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={landuse} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Land Use Pattern</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={sitevisit} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Ecology bio-diversity risk assessment</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src="https://picsum.photos/500/300/?image=10" /></div>
                            <div class="card_content">
                                <h2 class="card_title">CGWA compliance</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src="https://picsum.photos/500/300/?image=10" /></div>
                            <div class="card_content">
                                <h2 class="card_title">Water audit</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={flowmeter} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Flow meter installation</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src="https://picsum.photos/500/300/?image=10" /></div>
                            <div class="card_content">
                                <h2 class="card_title">Piezometer installation</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src="https://picsum.photos/500/300/?image=10" /></div>
                            <div class="card_content">
                                <h2 class="card_title">Environmental audit</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                    <li class="cards_item">
                        <div class="card">
                            <div class="card_image"><img src={laboratory} /></div>
                            <div class="card_content">
                                <h2 class="card_title">Co-ordination with Environmental Laboratory Test</h2>
                                {/* <p class="card_text">Demo of pixel perfect pure CSS simple responsive card grid layout</p>
                                <button class="btn card_btn">Read More</button> */}
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            {/* <div class="left-pane">
                <div class="info-card">
                    <h3>Hyderabad <br /><small>Telangana</small></h3>
                    <div class="hz">
                        <img src="https://ssl.gstatic.com/onebox/weather/32/fog.png" alt="" />
                        <small>Haze.31<sup>o</sup>C <br />12:38pm</small>
                    </div>
                </div>
                <hr />
                <div class="flex">
                    <div class="icon">
                        <i class="fa fa-map-marker"></i>
                        <p>Directions</p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-bookmark-o"></i>
                        <p>Save</p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-compass"></i>
                        <p>Compass</p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-mobile"></i>
                        <p>Send to phone</p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-share-alt"></i>
                        <p>Sahre</p>
                    </div>
                </div>
                <hr />
                <div class="facts">
                    <h4>Quick facts</h4>
                    <small>Hyderabad is the capital of southern India's Telangana state. A major center for the technology industry, it's home to many upscale restaurants and shops. Its historic sites include Golconda Fort, a former diamond-trading center that was once the Qutb Shahi dynastic capital. The Charminar, a 16th-century mosque whose 4 arches support towering minarets, is an old city landmark near the long-standing Laad Bazaar.</small>
                </div>
                <hr />
            </div> */}

            {/* <h3 class="made_by">Made with ♡</h3> */}
        </>
    )

}

export default Dashboard
