import { React, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-bootstrap";
import logo from '../img/logo.jpg';
import environment_horticulture from '../img/_1.jpg';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import '../css/dash.css';
import * as Icon from "react-bootstrap-icons";
import '../css/contactUs.css';
import $ from 'jquery';
import {FaMapMarkedAlt} from "react-icons";

function ContactUs() {

    return (
        <>
            <Navbar collapseOnSelect expand="lg" bg="white" variant="white" className="flex-column">
                <Container>
                    <Navbar.Brand href="/"><img src={logo} className="logo" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">

                        <Nav className="ms-auto">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="/aboutUs">About Us</Nav.Link>
                            <Nav.Link href="/contactUs">Contact Us</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <section class="contact-page-sec">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="contact-info">
                                <div class="contact-info-item">
                                    <div class="contact-info-icon">
                                        <Icon.PinMapFill
                                            size={"3em"}
                                            color={'#fda40b'}
                                        />
                                    </div>
                                    <div class="contact-info-text">
                                        <h2>Office Address</h2>
                                        <span className="p-1">A-1012, 10th Floor,</span>
                                        <span className="p-1">Titanium City Center Corporate Offices,</span>
                                        <span className="p-1">100 Ft. Anand Nagar Road</span>
                                        <span>Ahmedabad - 380015</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="contact-info">
                                <div class="contact-info-item">
                                    <div class="contact-info-icon">
                                        <Icon.EnvelopeFill
                                            size={"3em"}
                                            color={'#fda40b'}
                                        />
                                    </div>
                                    <div class="contact-info-text">
                                        <h2>E-mail</h2>
                                        <span className="p-1">svar.enviro@gmail.com</span>
                                        <span className="p-1"></span>
                                        <span className="p-1"></span>
                                        <span className="p-1"></span>
                                        <span className="p-1"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="contact-info">
                                <div class="contact-info-item">
                                    <div class="contact-info-icon">
                                        <Icon.ClockFill
                                            size={"3em"}
                                            color={'#fda40b'}
                                        />
                                    </div>
                                    <div class="contact-info-text">
                                        <h2>Contact Numbers</h2>
                                        <span className="p-1">9328331370</span>
                                        <span className="p-1">9825411945</span>
                                        <span className="p-1">9925034396</span>
                                        <span className="p-1"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="contact-page-form" method="post">
                                <h2>Get in Touch</h2>
                                <form action="contact-mail.php" method="post">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-6 col-xs-12">
                                            <div class="single-input-field">
                                                <input type="text" placeholder="Your Name" name="name" />
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-xs-12">
                                            <div class="single-input-field">
                                                <input type="email" placeholder="E-mail" name="email" required />
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-xs-12">
                                            <div class="single-input-field">
                                                <input type="text" placeholder="Phone Number" name="phone" />
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-xs-12">
                                            <div class="single-input-field">
                                                <input type="text" placeholder="Subject" name="subject" />
                                            </div>
                                        </div>
                                        <div class="col-md-12 message-input">
                                            <div class="single-input-field">
                                                <textarea placeholder="Write Your Message" name="message"></textarea>
                                            </div>
                                        </div>
                                        <div class="single-input-fieldsbtn">
                                            <input type="submit" value="Send Now" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/* <h3 class="made_by">Made with ♡</h3> */}
        </>
    )

}

export default ContactUs
