import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Switch, useHistory } from "react-router-dom";
import Dashboard from './components/index';
import AboutUs from './components/aboutUs';
import ContactUs from './components/contactUs';
import Sample from './pages/sample';

function App() {
  return (
    <Router
      getUserConfirmation={(message, callback) => {
        const allowTransition = window.confirm(message);
        callback(allowTransition);
      }}
    >
      <Switch>
        <Route exact path="/">
          <Dashboard/>
        </Route>

        <Route exact path="/aboutUs">
          <AboutUs/>
        </Route>
        
        <Route exact path="/contactUs">
          <ContactUs/>
        </Route>

        <Route exact path="/sample">
          <Sample/>
        </Route>
      </Switch>
    </Router>

  );
}

export default App;
