import { React, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-bootstrap";
import logo from '../img/logo.jpg';
import environment_horticulture from '../img/_1.jpg';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import '../css/dash.css';
import '../css/aboutUs.css';
import $ from 'jquery';

function AboutUs() {

    return (
        <>
            <Navbar collapseOnSelect expand="lg" bg="white" variant="white" className="flex-column">
                <Container>
                    <Navbar.Brand href="/"><img src={logo} className="logo" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">

                        <Nav className="ms-auto">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="/aboutUs">About Us</Nav.Link>
                            <Nav.Link href="/contactUs">Contact Us</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <section class="about-section">
                <div class="container">
                    <div class="row">
                        <div class="content-column col-12 order-2">
                            <div class="inner-column">
                                <div class="sec-title">
                                    <h2>We are Creative Agriculturist working since 2015</h2>
                                </div>
                                <div class="text">
                                    <b>SVAR Environments</b> is an expert consultancy services provider for various environmental services requirements namely, horticulture and landscaping, gardening, water and waste water management, green belt, ‘miyawaki’ plantation, plant nursery, green waste management, soil conservation and improvisation, plot area flood risk management, irrigation management, percolation well, rainwater harvesting, environmental requirements for surrounding areas, carbon footprint, factory waste management, special training program for environmental awareness, tree plantation in adopted areas, ground water studies, water pollution, land use pattern, site visits, water audit, environmental audit etc.
                                </div>
                                <div class="text">
                                    Our firm is engaged in rendering world-class Consultancy and Execution Services for various environmental services requirements to our clients. These services are appreciated for making optimum usage of the land, spell bounded beautification, organized execution and timely completion. Moreover, we have a team of professionals and gardeners, which helps us in offering services that enhances the look of outdoor and indoor spaces while meeting the expectations of our clients. We also undertake integrated PMC and also specialized in SOP verification, pre, post and concurrent audits of all services.
                                </div>
                                {/* <div class="btn-box">
                                    <a href="#" class="theme-btn btn-style-one">Contact Us</a>
                                </div> */}
                            </div>
                        </div>

                    </div>
                </div>
                <div class="container">
                    <div class="sec-title">
                        <h1 style={{textAlign:'center', fontSize:'45px'}}>Our Team</h1>
                    </div>
                    <div class="row team">
                        <div class="col-md-6 member">
                            
                            <h3>Aloukik Dave</h3>
                            <p>
                                A passionate Environmental Engineer dedicated to designing and assessing the environmental impact of engineering plans. An M. Tech in Civil Engineering specialized in Environmental Engineering, proficient at completing environmental investigations on upcoming engineering projects, developing and implementing environmental improvement programs, and collecting and analysing field data. Specializes at developing goals and environmental parameters for engineering projects.
                            </p>
                            <p>
                                His core strengths are in the areas of Environmental Investigation, Project reviews, Environmental impact assessment, Improvement programs,  Regulatory compliance, Environmental policy, Budgeting and administration.
                            </p>
                            <p>
                                Now, his focus is on Green building, or sustainable design, by increasing the efficiency with which buildings and their sites use energy, water, and materials, and reducing building impacts on human health and the environment over the entire life cycle of the building.
                            </p>
                        </div>
                        <div class="col-md-6 member">
                            
                            <h3>Vanraj Padheriya</h3>
                            <p>
                                A fervent Horticultural and Landscape professional with over 18 years of experience in conceptualising, developing and maintaining horticulture / landscaping projects in and around Ahmedabad.
                            </p>
                            <p>
                                A qualified agricultural professional from Navsari Agriculture University, specialised in horticulture, has remarkable experience in cultivation, propagation and care of plants, strong knowledge of the practices and principles of horticulture, solid understanding of maintaining plants in the aesthetic and cultural style, familiar with the establishment and maintenance of natural community based gardens, having thorough knowledge of organic and natural pesticide and fungicide control.
                            </p>
                            <p>
                                He has completed major horticultural and landscaping projects for Adani Group, Alembic Group, Cadila Pharma and Intas Group.
                            </p>
                        </div>
                    </div>
                    <div class="row team">
                        <div class="col-md-6 member">
                            
                            <h3>Saji Joseph</h3>
                            <p>
                                A Management professional with 28-years portfolio of success across project management, organizational development, client services, business development and counselling leadership.
                            </p>
                            <p>
                                He has started his career way back in 1993 with a private limited company in Ahmedabad and moved on to work with CARE Ratings Ltd (Leading rating and advisory services company promoted by IDBI) and instrumental in setting-up its regional office in Ahmedabad. Later on he was associated with Pharma major Cadila Pharmaceuticals Ltd for over 17 years. He was closely associated with its top management, handling various prestigious projects and administration.
                            </p>
                            <p>
                                A talented Corporate Social Responsibility professional with experience in managing small and large scale projects, he was heading the CSR wing of the company. He demonstrates strong ability to exercise solid judgment and diplomacy in a fast-paced environment.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* <h3 class="made_by">Made with ♡</h3> */}
        </>
    )

}

export default AboutUs
